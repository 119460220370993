"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.SEND_LOGS_BASE_PATH = exports.MISSING_REQUIRED_PARAMS_MESSAGE = void 0;
var SEND_LOGS_BASE_PATH = 'https://logs.browser-intake-datadoghq.com/api/v2/';
exports.SEND_LOGS_BASE_PATH = SEND_LOGS_BASE_PATH;
var MISSING_REQUIRED_PARAMS_MESSAGE = 'The DataDog analytics post was not fired due to missing required parameters';
exports.MISSING_REQUIRED_PARAMS_MESSAGE = MISSING_REQUIRED_PARAMS_MESSAGE;