"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _lodash = _interopRequireDefault(require("lodash"));
var _watch = require("../../util/watch");
var formatEpisodeDetail = function formatEpisodeDetail(options) {
  var metadata = options.metadata;
  var isSportingEvent = (0, _watch.getWatchMetadata)(metadata, _watch.METADATA.ASSET_SERIES_TYPE) === 'sportingEvent';
  var seasonNumber = (0, _watch.getWatchMetadata)(metadata, _watch.METADATA.ASSET_SEASON_NUMBER);
  var episodeNumber = (0, _watch.getWatchMetadata)(metadata, _watch.METADATA.ASSET_EPISODE_NUMBER);
  var episodeTitle = (0, _watch.getWatchMetadata)(metadata, _watch.METADATA.ASSET_NAME);
  var seriesName = (0, _watch.getWatchMetadata)(metadata, _watch.METADATA.ASSET_SERIES_NAME);
  if (isSportingEvent) return _lodash["default"].get(options, 'metadata.sportTag', '');
  if (!episodeTitle) return '';
  if (seasonNumber && episodeNumber) {
    var _context, _context2;
    return (0, _concat["default"])(_context = (0, _concat["default"])(_context2 = "S".concat(seasonNumber, " E")).call(_context2, episodeNumber, " ")).call(_context, episodeTitle);
  }
  if (episodeTitle !== seriesName) return episodeTitle;
  return '';
};
var _default = formatEpisodeDetail;
exports["default"] = _default;