"use strict";

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");
var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");
var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _forEachInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");
var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");
var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");
var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.getDRMType = exports.getDRM = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));
var _isNan = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/number/is-nan"));
var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.string.search.js");
var _lodash = _interopRequireDefault(require("lodash"));
var _bowser = _interopRequireDefault(require("bowser"));
var _device = require("./device");
var _shared = require("./shared");
function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var _context, _context2; var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? _forEachInstanceProperty(_context = ownKeys(Object(source), !0)).call(_context, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : _forEachInstanceProperty(_context2 = ownKeys(Object(source))).call(_context2, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } return target; }
_lodash["default"].noConflict();
var getSPCUrl = function getSPCUrl(initDataUri) {
  var spcurl = initDataUri.replace('skd://', 'https://');
  spcurl = spcurl.substring(1, spcurl.length);
  return spcurl;
};
var base64EncodeUint8Array = function base64EncodeUint8Array(input) {
  var keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  var output = '';
  var chr1;
  var chr2;
  var chr3;
  var enc1;
  var enc2;
  var enc3;
  var enc4;
  var i = 0;
  while (i < input.length) {
    chr1 = input[i];
    i += 1;
    if (i < input.length) {
      chr2 = input[i];
      i += 1;
    } else {
      chr2 = Number.NaN;
    }
    if (i < input.length) {
      chr3 = input[i];
      i += 1;
    } else {
      chr3 = Number.NaN;
    }
    enc1 = chr1 >> 2;
    enc2 = (chr1 & 3) << 4 | chr2 >> 4;
    enc3 = (chr2 & 15) << 2 | chr3 >> 6;
    enc4 = chr3 & 63;
    if ((0, _isNan["default"])(chr2)) {
      enc4 = 64;
      enc3 = 64;
    } else if ((0, _isNan["default"])(chr3)) {
      enc4 = 64;
    }
    output += keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
  }
  return output;
};
var getDRM = function getDRM(options) {
  var drm = null;
  var fairplayCertificateURL = _lodash["default"].get(options, 'drm.fairplayCertificateURL');
  var widevineLicenseURL = _lodash["default"].get(options, 'drm.widevineLicenseURL');
  var playreadyLicenseURL = _lodash["default"].get(options, 'drm.playreadyLicenseURL');
  if (fairplayCertificateURL) {
    drm = {
      fairplay: {
        certificateUrl: fairplayCertificateURL,
        extractContentId: function extractContentId(initDataUri) {
          var link = document.createElement('a');
          link.href = getSPCUrl(initDataUri);
          var query = link.search.substr(1);
          var id = query.split('&');
          var item = id[0].split('=');
          var cid = item[1];
          return cid;
        },
        processSpcUrl: getSPCUrl,
        licenseResponseType: 'json',
        licenseRequestMessage: function licenseRequestMessage(message, session) {
          var payload = {};
          payload.spc = base64EncodeUint8Array(message);
          payload.assetId = session.contentId;
          return (0, _stringify["default"])(payload);
        },
        extractKey: function extractKey(response) {
          return response.ckc;
        }
      }
    };
  } else if (widevineLicenseURL || playreadyLicenseURL) {
    drm = _objectSpread(_objectSpread({}, widevineLicenseURL && {
      widevine: {
        url: widevineLicenseURL
      }
    }), playreadyLicenseURL && {
      playready: {
        url: playreadyLicenseURL
      }
    });
  }
  return drm;
};
exports.getDRM = getDRM;
var getDRMType = function getDRMType() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var isTizen = options.isTizen;
  var isVizio = options.isVizio;
  var browser = _bowser["default"].getParser(window.navigator.userAgent);
  var userAgent = _bowser["default"].parse(window.navigator.userAgent);
  var osName = _lodash["default"].get(userAgent, 'os.name');
  var osVersion = _lodash["default"].get(userAgent, 'os.version');
  var osVersionName = _lodash["default"].get(userAgent, 'os.versionName');
  var browserName = browser.getBrowserName();
  var browserVersion = browser.getBrowserVersion();

  // OS detections
  var isMacOS1011Plus = false;
  var isIOS112Plus = false;
  var isWindows81Plus = false;
  if (osName === 'macOS' && (0, _shared.greaterThanOrEqual)(osVersion, '10.11')) {
    isMacOS1011Plus = true;
  }
  if (osName === 'iOS' && (0, _shared.greaterThanOrEqual)(osVersion, '11.2')) {
    isIOS112Plus = true;
  }
  if (osName === 'Windows' && (0, _shared.greaterThanOrEqual)(osVersionName, '8.1')) {
    isWindows81Plus = true;
  }
  var isWindows = osName === 'Windows';
  var isMacOS = osName === 'macOS';
  var isAndroid = osName === 'Android';

  // Browser detections
  var isSafari = browserName === 'Safari';
  var isChrome62Plus = browser.satisfies({
    chrome: '>=62'
  });
  var isFirefox47Plus = browser.satisfies({
    firefox: '>=47'
  });
  var isIE11Plus = browser.satisfies({
    ie: '>=11'
  });
  var isEdgeChromium = browser.satisfies({
    edge: '>=79'
  });
  var isEdgeLegacy = false;
  if (browserName === 'Microsoft Edge' && (0, _shared.greaterThanOrEqual)(browserVersion, '12') && (0, _shared.lessThan)(browserVersion, '79')) {
    isEdgeLegacy = true;
  }

  // Device detections
  var isDesktop = _lodash["default"].get(userAgent, 'platform.type') === 'desktop';
  if (isIOS112Plus || isSafari && isMacOS1011Plus) {
    return 'drm/fairplay';
  }
  if (isTizen || isVizio || (0, _device.isChromecastReceiver)(options) || isChrome62Plus && (isDesktop || isAndroid) || isFirefox47Plus && (isWindows || isMacOS) || isEdgeChromium && (isWindows || isMacOS || isAndroid)) {
    return 'drm/widevine';
  }
  if (isIE11Plus && isWindows81Plus || isEdgeLegacy && isWindows) {
    return 'drm/playready';
  }
  return null;
};
exports.getDRMType = getDRMType;