"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.setCookie = exports.getCookie = void 0;
require("core-js/modules/es.date.to-string.js");
var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));
var _lodash = _interopRequireDefault(require("lodash"));
var getCookie = function getCookie(name) {
  var _context;
  var value = _lodash["default"].get(window, "Windows.Storage.ApplicationData.current.localSettings.values.".concat(name));
  if (typeof value !== 'undefined') return value;
  if (!document.cookie) return '';
  var cookie = (0, _find["default"])(_context = document.cookie.split('; ')).call(_context, function (obj) {
    return obj.split('=')[0] === name;
  });
  return typeof cookie !== 'undefined' ? cookie.split('=')[1] : '';
};
exports.getCookie = getCookie;
var setCookie = function setCookie(name, value) {
  if (_lodash["default"].get(window, 'Windows.Storage.ApplicationData.current.localSettings')) {
    _lodash["default"].set(window, "Windows.Storage.ApplicationData.current.localSettings.values.".concat(name), value);
  } else {
    var _context2, _context3;
    var expiry = new Date();
    expiry.setDate(expiry.getDate() + 365); // our cookie will last a year
    document.cookie = (0, _concat["default"])(_context2 = (0, _concat["default"])(_context3 = "".concat(name, "=")).call(_context3, (0, _stringify["default"])(value), "; path=/; expires=")).call(_context2, expiry.toUTCString());
  }
};
exports.setCookie = setCookie;