"use strict";

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
_Object$defineProperty(exports, "Analytics", {
  enumerable: true,
  get: function get() {
    return _analytics["default"];
  }
});
_Object$defineProperty(exports, "AnalyticsConfig", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
_Object$defineProperty(exports, "Config", {
  enumerable: true,
  get: function get() {
    return _config2["default"];
  }
});
_Object$defineProperty(exports, "DataDog", {
  enumerable: true,
  get: function get() {
    return _dataDog["default"];
  }
});
exports.helpers = void 0;
var _helpers = _interopRequireWildcard(require("./helpers"));
exports.helpers = _helpers;
var _analytics = _interopRequireDefault(require("./analytics"));
var _config = _interopRequireDefault(require("./analytics/config"));
var _dataDog = _interopRequireDefault(require("./analytics/data-dog"));
var _config2 = _interopRequireDefault(require("./config"));
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = _Object$defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { _Object$defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }