"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var errorTypes = {
  PLAY_BAD_REQUEST_ERROR: {
    name: 'play-bad-request-error',
    code: 'util/player-http-400'
  },
  PLAY_FORBIDDEN_REQUEST: {
    name: 'play-forbidden-request',
    code: 'util/player-http-403'
  },
  PLAY_FORBIDDEN_REQUEST_TOKEN_ERROR: {
    name: 'play-forbidden-request-token-error',
    code: 'util/player-http-403'
  },
  PLAY_FORBIDDEN_REQUEST_GEO_ERROR: {
    name: 'play-forbidden-request-geo-error',
    code: 'util/player-http-403'
  },
  PLAY_REQUEST_ERROR_UNKNOWN: {
    name: 'play-request-error-unknown',
    code: 'util/player-http-unknown'
  }
};
var _default = errorTypes;
exports["default"] = _default;