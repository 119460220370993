"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
_Object$defineProperty(exports, "Metadata", {
  enumerable: true,
  get: function get() {
    return _metadata["default"];
  }
});
_Object$defineProperty(exports, "MetadataConfig", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
var _metadata = _interopRequireDefault(require("./metadata"));
var _config = _interopRequireDefault(require("./metadata/config"));