"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.isTablet = exports.isSamsung = exports.isSafari = exports.isMobile = exports.isIpad = exports.isIOS = exports.isChromeiOS = exports.isChromecastReceiver = exports.isChromeAndroid = exports.isChrome = exports.isAndroid = exports.getWindowSize = exports.getOS = exports.getMaxRes = exports.UHD_SDR = exports.UHD_HDR = exports.PLAYER_TYPE = exports.DEFAULT_RESOLUTION_LABEL = void 0;
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.match.js");
var _lodash = _interopRequireDefault(require("lodash"));
var _currentDevice = _interopRequireDefault(require("current-device"));
_lodash["default"].noConflict();
var PLAYER_TYPE = {
  CHROMECAST_SENDER: 'ChromecastSender',
  // to match the config v
  CHROMECAST_RECEIVER: 'Chromecast',
  JW: 'JW'
};
exports.PLAYER_TYPE = PLAYER_TYPE;
var DEFAULT_RESOLUTION_LABEL = '720p';
exports.DEFAULT_RESOLUTION_LABEL = DEFAULT_RESOLUTION_LABEL;
var UHD_SDR = 'UHD/SDR';
exports.UHD_SDR = UHD_SDR;
var UHD_HDR = 'UHD/HDR';

// To add more device checks see:
// https://github.com/matthewhudson/device.js?#device-javascript-methods
exports.UHD_HDR = UHD_HDR;
var ua = navigator.userAgent;
var isTablet = function isTablet() {
  return _currentDevice["default"].tablet();
};
exports.isTablet = isTablet;
var isMobile = function isMobile() {
  return _currentDevice["default"].mobile();
};
exports.isMobile = isMobile;
var isIOS = function isIOS() {
  return _currentDevice["default"].ios();
};
exports.isIOS = isIOS;
var isIpad = function isIpad() {
  return _currentDevice["default"].ipad();
};
exports.isIpad = isIpad;
var isAndroid = function isAndroid() {
  return _currentDevice["default"].android();
};
exports.isAndroid = isAndroid;
var isSafari = function isSafari() {
  return !!ua.match(/Version\/[\d.]+.*Safari/);
};
exports.isSafari = isSafari;
var isSamsung = function isSamsung() {
  return /Tizen/.test(ua) && /SamsungBrowser/.test(ua);
};
exports.isSamsung = isSamsung;
var isChrome = function isChrome() {
  return /Chrome/.test(ua) && /Google Inc/.test(navigator.vendor) && !isSamsung() || !!_lodash["default"].get(window, 'chrome.cast', false);
};
exports.isChrome = isChrome;
var isChromeiOS = function isChromeiOS() {
  return !!ua.match('CriOS');
};
exports.isChromeiOS = isChromeiOS;
var isChromeAndroid = function isChromeAndroid() {
  return (0, _indexOf["default"])(ua).call(ua, 'Android') !== -1 && !!ua.match(/Chrome\/[.0-9]+ Mobile/);
};
exports.isChromeAndroid = isChromeAndroid;
var isChromecastReceiver = function isChromecastReceiver(options) {
  return _lodash["default"].get(options, 'playerType') === PLAYER_TYPE.CHROMECAST_RECEIVER;
};
exports.isChromecastReceiver = isChromecastReceiver;
var getWindowSize = function getWindowSize() {
  if (typeof window === 'undefined') return null;
  var body = document.documentElement.getElementsByTagName('body')[0];
  var width = window.innerWidth || document.documentElement.clientWidth || body.clientWidth;
  var height = window.innerHeight || document.documentElement.clientHeight || body.clientHeight;
  return {
    width: width,
    height: height
  };
};
exports.getWindowSize = getWindowSize;
var getOS = function getOS() {
  return _currentDevice["default"].os || 'unknown';
};
exports.getOS = getOS;
var getMaxRes = function getMaxRes(options) {
  var uhdEnabled = _lodash["default"].get(options, 'config.uhdEnabled', false);
  try {
    if (uhdEnabled && !_lodash["default"].isUndefined(_lodash["default"].get(window, 'webapis.productinfo')) && !_lodash["default"].isUndefined(_lodash["default"].get(window, 'webapis.avinfo'))) {
      if (window.webapis.productinfo.isUdPanelSupported()) {
        return window.webapis.avinfo.isHdrTvSupport() ? UHD_HDR : UHD_SDR;
      }
    }
  } catch (error) {
    // Unable to access system level API on device
  }
  return DEFAULT_RESOLUTION_LABEL;
};
exports.getMaxRes = getMaxRes;