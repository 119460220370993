"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.videoInterruptionMethods = exports.getVideoShowCode = exports.getVideoIsResume = exports.getVideoIsDVR = exports.getVideoIsContinuous = exports.getVideoContentTags = exports.getVideoContentLengthFormat = exports.getUtagDataPageType = exports.getUtagDataPageName = exports.getUserXid = exports.getUsPrivacy = exports.getSport = exports.getSourceNetwork = exports.getSelectedItemType = exports.getSelectedItemTitle = exports.getSelectedComponentTitle = exports.getSelectedComponentPosition = exports.getSecondaryBusinessUnit = exports.getSeasonNumber = exports.getProfileId = exports.getPrimaryBusinessUnit = exports.getPageUrlFromCustomParams = exports.getPageUrl = exports.getPageTypeFromCustomParams = exports.getPageTestName = exports.getPageNameFromCustomParams = exports.getPageContentId = exports.getOriginator = exports.getNielsenOptOutStatus = exports.getNetworkEntitlementList = exports.getMvpdId = exports.getMediaId = exports.getMaxResolution = exports.getMaxBitrate = exports.getLvsType = exports.getLeague = exports.getLastAnonProfileId = exports.getHdrSupport = exports.getGenre = exports.getFormattedAirDate = exports.getFeedName = exports.getEvent = exports.getEpisodeNumber = exports.getEntityId = exports.getECID = exports.getCurrentResolution = exports.getCurrentBitrate = exports.getContentType = exports.getContentSubscriptionType = exports.getAuthorizingNetwork = exports.getAuthenticationState = exports.getAppVersionFromCustomParams = exports.getAppVersion = exports.getAppPlatformFromCustomParams = exports.getAppPlatform = exports.getAppName = exports.getAppBuild = exports.getAccountId = void 0;
require("core-js/modules/es.date.to-string.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.array.join.js");
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
var _setTimeout2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-timeout"));
var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));
var _lodash = _interopRequireDefault(require("lodash"));
var _device = require("./device");
var _watch = require("./watch");
var _logger = _interopRequireDefault(require("../helpers/logger"));
var _this = void 0;
var log = new _logger["default"](': analytics util module :');
var getAppName = function getAppName(options) {
  var appName = (0, _device.isChromecastReceiver)(options) ? _lodash["default"].get(options, 'analytics.Segment.config.app_name') : _lodash["default"].get(options, 'params.utag_data.app_name');
  if (appName && (0, _includes["default"])(appName).call(appName, ' chromecast')) {
    return appName.substring(0, (0, _indexOf["default"])(appName).call(appName, ' chromecast'));
  }
  return appName;
};
exports.getAppName = getAppName;
var getAppBuild = function getAppBuild(options) {
  return _lodash["default"].get(options, 'params.utag_data.app_build');
};
exports.getAppBuild = getAppBuild;
var getAppPlatform = function getAppPlatform(options) {
  var appPlatform = (0, _device.isChromecastReceiver)(options) ? _lodash["default"].get(options, 'analytics.Segment.config.page_app_platform') : _lodash["default"].get(options, 'watch.app.platform') || _lodash["default"].get(options, 'params.utag_data.app_platform');
  return appPlatform;
};
exports.getAppPlatform = getAppPlatform;
var getAppVersion = function getAppVersion(options) {
  var appVersion = (0, _device.isChromecastReceiver)(options) ? _lodash["default"].get(global, 'appVersion') : _lodash["default"].get(options, 'params.utag_data.app_version');
  return appVersion;
};
exports.getAppVersion = getAppVersion;
var getPrimaryBusinessUnit = function getPrimaryBusinessUnit(options) {
  var primaryBusinessUnit = (0, _device.isChromecastReceiver)(options) ? _lodash["default"].get(options, 'analytics.Segment.config.primary_business_unit') : _lodash["default"].get(options, 'params.utag_data.primary_business_unit');
  return primaryBusinessUnit;
};
exports.getPrimaryBusinessUnit = getPrimaryBusinessUnit;
var getSecondaryBusinessUnit = function getSecondaryBusinessUnit(options) {
  var secondaryBusinessUnit = (0, _device.isChromecastReceiver)(options) ? _lodash["default"].get(options, 'analytics.Segment.config.secondary_business_unit') : _lodash["default"].get(options, 'params.utag_data.secondary_business_unit');
  return secondaryBusinessUnit;
};
exports.getSecondaryBusinessUnit = getSecondaryBusinessUnit;
var getNielsenOptOutStatus = function getNielsenOptOutStatus() {
  (0, _setTimeout2["default"])(function () {
    var nielsenGlobal = _lodash["default"].get(window, 'NOLBUNDLE', null);
    if (nielsenGlobal !== null) {
      try {
        var optOutStatus = nielsenGlobal.getInstance().getOptOutStatus();
        _this.options.nielsenOptOut = optOutStatus.toString();
      } catch (e) {
        log.error('Failed to read Nielsen Opt-Out Status', e);
      }
    }
  }, 1000);
};

// metadata methods
exports.getNielsenOptOutStatus = getNielsenOptOutStatus;
var getAuthorizingNetwork = function getAuthorizingNetwork(options, network) {
  var contentSKU = _lodash["default"].get(options, 'metadata.contentSKU', network);
  if ((0, _isArray["default"])(contentSKU) && contentSKU.length) {
    return contentSKU[0].replace('us.', '');
  }
  return contentSKU;
};
exports.getAuthorizingNetwork = getAuthorizingNetwork;
var getGenre = function getGenre(videoInfo, defaultValues) {
  var genre = defaultValues.genre;
  var genres = (0, _watch.getWatchMetadata)(videoInfo, _watch.METADATA.ASSET_GENRES);
  if (!videoInfo || !genres) return genre;
  if (genres.length < 1) return genre;
  if ((0, _isArray["default"])(genres)) genre = genres.join(', ');
  return genre;
};
exports.getGenre = getGenre;
var getFormattedAirDate = function getFormattedAirDate(airDate, defaultValues) {
  var clipDate = function clipDate(date) {
    var _context;
    return (0, _slice["default"])(_context = "0".concat(date)).call(_context, -2);
  };
  if (airDate !== defaultValues.originalAirDate) {
    var _context2, _context3;
    var date = new Date(airDate);
    var year = date.getFullYear();
    var month = clipDate(date.getMonth() + 1);
    var day = clipDate(date.getDate());
    airDate = (0, _concat["default"])(_context2 = (0, _concat["default"])(_context3 = "".concat(month, "/")).call(_context3, day, "/")).call(_context2, year);
  }
  return airDate;
};
exports.getFormattedAirDate = getFormattedAirDate;
var getContentSubscriptionType = function getContentSubscriptionType(tveEnabled, requiresValue) {
  var noSubscription = 'no subscription type';
  if (!tveEnabled || requiresValue === noSubscription) {
    return noSubscription;
  }
  return requiresValue ? 'locked' : 'unlocked';
};
exports.getContentSubscriptionType = getContentSubscriptionType;
var getLvsType = function getLvsType(videoType, videoTypesMap) {
  if (videoType === 'no format') {
    return videoType;
  }
  return videoTypesMap[videoType];
};

// TODO - why is tveEnabled global!?
exports.getLvsType = getLvsType;
var getMvpdId = function getMvpdId(options, defaultValues) {
  if (!tveEnabled) {
    return defaultValues.mvpd;
  }
  return _lodash["default"].get(options.auth, 'mvpdId', defaultValues.mvpd);
};
exports.getMvpdId = getMvpdId;
var getMediaId = function getMediaId(options, defaultValues) {
  var mediaId = _lodash["default"].get(options, 'videoID', defaultValues.mediaId);
  // no need to add an extra  check for live since assetInfo
  // would only be present for live assets
  return _lodash["default"].get(options, 'metadata.assetInfo.asset', mediaId);
};
exports.getMediaId = getMediaId;
var getContentType = function getContentType(videoType) {
  if (!videoType) return '';
  videoType = videoType.toLowerCase();
  if (!videoType || videoType === 'clip' || videoType === 'digitalexclusive') return 'short-form';
  if (!videoType || videoType === 'episod' || videoType === 'fullepisode') return 'short-form';
  return videoType;
};
exports.getContentType = getContentType;
var getProfileId = function getProfileId(options, defaultValues) {
  var profileAnonymous = _lodash["default"].get(options, 'auth.profile.profileAnonymous', false);
  if (profileAnonymous) return defaultValues.dcg_profile_id;
  return _lodash["default"].get(options, 'auth.profile.profileId', defaultValues.dcg_profile_id);
};
exports.getProfileId = getProfileId;
var getAuthenticationState = function getAuthenticationState(options, isPreviewPass) {
  // utag data is not accurate since it refers to the state on page load and would only be
  // accurate if you visit a page and you're already authenticated.
  // If you visit a page and a video requries auth, auth object will be set
  // while utag data will still refer to the original page state
  var mvpdId = _lodash["default"].get(options.auth, 'mvpdId');
  if (tveEnabled && mvpdId && !isPreviewPass(mvpdId)) {
    return 'authenticated';
  }
  return _lodash["default"].get(window, 'utag.data.authentication_state', 'not authenticated');
};
exports.getAuthenticationState = getAuthenticationState;
var getNetworkEntitlementList = function getNetworkEntitlementList(options, defaultValues) {
  var entitlementList = _lodash["default"].get(options.auth, 'networkEntitlementList', defaultValues.networkEntitlementList);
  if ((0, _isArray["default"])(entitlementList)) {
    return (0, _sort["default"])(entitlementList).call(entitlementList).join();
  }
  return entitlementList;
};
exports.getNetworkEntitlementList = getNetworkEntitlementList;
var getFeedName = function getFeedName(callSign, live, defaultValues, channels) {
  if (!live) return defaultValues.feedname;
  return "".concat(channels[callSign] || 'Fox', " Live Stream");
};
exports.getFeedName = getFeedName;
var getOriginator = function getOriginator(originator, defaultValues) {
  if (originator === defaultValues.network) originator = defaultValues.originator;
  return originator;
};
exports.getOriginator = getOriginator;
var getSeasonNumber = function getSeasonNumber(season, defaultValues) {
  if (season !== defaultValues.seasonNumber) season = "s".concat(season);
  return season;
};
exports.getSeasonNumber = getSeasonNumber;
var getEpisodeNumber = function getEpisodeNumber(episode, defaultValues) {
  if (episode !== defaultValues.episodeNumber) episode = "e".concat(episode);
  return episode;
};
exports.getEpisodeNumber = getEpisodeNumber;
var getPageTestName = function getPageTestName(options) {
  return _lodash["default"].get(options, 'params.page_test_name');
};
exports.getPageTestName = getPageTestName;
var getUtagDataPageName = function getUtagDataPageName(options) {
  return _lodash["default"].get(options, 'watch.app.page_name') || _lodash["default"].get(options, 'params.utag_data.page_name');
};
exports.getUtagDataPageName = getUtagDataPageName;
var getUtagDataPageType = function getUtagDataPageType(options) {
  return _lodash["default"].get(options, 'watch.app.page_type') || _lodash["default"].get(options, 'params.utag_data.page_type');
};
exports.getUtagDataPageType = getUtagDataPageType;
var getLastAnonProfileId = function getLastAnonProfileId(options) {
  return _lodash["default"].get(options, 'auth.profile.lastAnonymousProfileId');
};
exports.getLastAnonProfileId = getLastAnonProfileId;
var getMaxResolution = function getMaxResolution(options) {
  return _lodash["default"].get(options, 'metadata.trackingData.properties.maxAvailableResolution');
};
exports.getMaxResolution = getMaxResolution;
var getMaxBitrate = function getMaxBitrate(options) {
  return _lodash["default"].get(options, 'metadata.trackingData.properties.maxAvailableBitrate');
};
exports.getMaxBitrate = getMaxBitrate;
var getHdrSupport = function getHdrSupport(options) {
  return _lodash["default"].get(options, 'metadata.trackingData.properties.hdrSupport');
};
exports.getHdrSupport = getHdrSupport;
var getCurrentBitrate = function getCurrentBitrate(options) {
  return _lodash["default"].get(options, 'visualQuality.bitrate');
};
exports.getCurrentBitrate = getCurrentBitrate;
var getCurrentResolution = function getCurrentResolution(options) {
  var screenHeight = _lodash["default"].get(options, 'visualQuality.height');
  return screenHeight ? "".concat(screenHeight, "p") : screenHeight;
};
exports.getCurrentResolution = getCurrentResolution;
var getUsPrivacy = function getUsPrivacy(options) {
  var optOutData = _lodash["default"].get(options, 'params.ads.privacyParameter');
  if (optOutData === '1YYN') {
    return '0';
  }
  if (optOutData === '1YNN') {
    return '1';
  }
  return '';
};
exports.getUsPrivacy = getUsPrivacy;
var getPageContentId = function getPageContentId(options) {
  return _lodash["default"].get(options, 'watch.app.page_content_id');
};
exports.getPageContentId = getPageContentId;
var getPageUrl = function getPageUrl(options) {
  return _lodash["default"].get(options, 'watch.app.page_url');
};
exports.getPageUrl = getPageUrl;
var getSelectedComponentPosition = function getSelectedComponentPosition(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.selectedComponentPosition');
};
exports.getSelectedComponentPosition = getSelectedComponentPosition;
var getSelectedComponentTitle = function getSelectedComponentTitle(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.selectedComponentTitle');
};
exports.getSelectedComponentTitle = getSelectedComponentTitle;
var getSelectedItemTitle = function getSelectedItemTitle(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.selectedItemTitle');
};
exports.getSelectedItemTitle = getSelectedItemTitle;
var getSelectedItemType = function getSelectedItemType(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.selectedItemType');
};
exports.getSelectedItemType = getSelectedItemType;
var getVideoIsResume = function getVideoIsResume(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.videoIsResume');
};
exports.getVideoIsResume = getVideoIsResume;
var getVideoContentLengthFormat = function getVideoContentLengthFormat(options) {
  var value = _lodash["default"].get(options, 'params.analytics.adobe.customfields.videoContentLengthFormat');
  return value || 'no format';
};
exports.getVideoContentLengthFormat = getVideoContentLengthFormat;
var getVideoContentTags = function getVideoContentTags(options) {
  var _$get;
  return (_$get = _lodash["default"].get(options, 'params.analytics.adobe.customfields.videoContentTags')) === null || _$get === void 0 ? void 0 : _$get.toString();
};
exports.getVideoContentTags = getVideoContentTags;
var getVideoIsContinuous = function getVideoIsContinuous(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.videoIsContinuous', false).toString();
};
exports.getVideoIsContinuous = getVideoIsContinuous;
var getVideoShowCode = function getVideoShowCode(options) {
  var metadata = options.metadata;
  var showCode = (0, _watch.getWatchMetadata)(metadata, _watch.METADATA.ASSET_SHOW_CODE);
  if (!showCode) {
    var _context4;
    var videoFormat = getVideoContentLengthFormat(options);
    var streamType = (0, _watch.getWatchMetadata)(metadata, _watch.METADATA.STREAM_TYPE) || '';
    streamType = streamType.toLowerCase();
    if (streamType === 'live') {
      return 'no show code live long-form';
    }
    return (0, _concat["default"])(_context4 = "no show code ".concat(streamType.toLowerCase(), " ")).call(_context4, videoFormat);
  }
  return showCode;
};
exports.getVideoShowCode = getVideoShowCode;
var getSourceNetwork = function getSourceNetwork(options) {
  return (0, _watch.getWatchMetadata)(options.metadata, _watch.METADATA.ASSET_PARTNER);
};
exports.getSourceNetwork = getSourceNetwork;
var getEntityId = function getEntityId(options) {
  return _lodash["default"].get(options, 'watch.playback.entity_id');
};
exports.getEntityId = getEntityId;
var getVideoIsDVR = function getVideoIsDVR(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.videoIsDVR');
};
//   {
//   // const streamCapabilities = _.get(
//   //   getWatchMetadata(options.metadata, METADATA.STREAM_CAPABILITIES),
//   //   '[0]', '',
//   // ).toLowerCase();

//   // return (
//   //   streamCapabilities === 'mode:livedvr'
//   //     || streamCapabilities === 'mode:growingrestart'
//   //     || streamCapabilities === 'mode:rollingrestart'
//   // ).toString();
// };
exports.getVideoIsDVR = getVideoIsDVR;
var getSport = function getSport(options) {
  return _lodash["default"].get(options, 'watch.playback.video_sport');
};
exports.getSport = getSport;
var getLeague = function getLeague(options) {
  return _lodash["default"].get(options, 'watch.playback.video_league');
};
exports.getLeague = getLeague;
var getEvent = function getEvent(options) {
  return _lodash["default"].get(options, 'watch.playback.video_event');
};
exports.getEvent = getEvent;
var getUserXid = function getUserXid(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.XID');
};
exports.getUserXid = getUserXid;
var getAccountId = function getAccountId(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.accountId');
};
exports.getAccountId = getAccountId;
var getECID = function getECID(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.ecid');
};
exports.getECID = getECID;
var getAppVersionFromCustomParams = function getAppVersionFromCustomParams(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.appVersion');
};
exports.getAppVersionFromCustomParams = getAppVersionFromCustomParams;
var getAppPlatformFromCustomParams = function getAppPlatformFromCustomParams(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.platform');
};
exports.getAppPlatformFromCustomParams = getAppPlatformFromCustomParams;
var getPageNameFromCustomParams = function getPageNameFromCustomParams(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.pageName');
};
exports.getPageNameFromCustomParams = getPageNameFromCustomParams;
var getPageTypeFromCustomParams = function getPageTypeFromCustomParams(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.pageType');
};
exports.getPageTypeFromCustomParams = getPageTypeFromCustomParams;
var getPageUrlFromCustomParams = function getPageUrlFromCustomParams(options) {
  return _lodash["default"].get(options, 'params.analytics.adobe.customfields.pageUrl');
};
exports.getPageUrlFromCustomParams = getPageUrlFromCustomParams;
var videoInterruptionMethods = {
  videoFailedToLoad: 'video failed to load',
  connectionError: 'connection error',
  screenDisruption: 'screen disruption',
  adInteraction: 'ad interaction',
  deviceAction: 'device action',
  browserAction: 'browser action'
};
exports.videoInterruptionMethods = videoInterruptionMethods;