"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  'ddsource': 'foxkit',
  'error.kind': '',
  'http.url_details.host': '',
  'http.status_code': null,
  'http.url': '',
  'http.url_details.path': '',
  'message': '',
  'status': 'error'
};
exports["default"] = _default;