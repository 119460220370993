"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
_Object$defineProperty(exports, "DataDogSendLogs", {
  enumerable: true,
  get: function get() {
    return _SendLogs["default"];
  }
});
_Object$defineProperty(exports, "DataDogSendLogsConfig", {
  enumerable: true,
  get: function get() {
    return _config["default"];
  }
});
var _SendLogs = _interopRequireDefault(require("./SendLogs"));
var _config = _interopRequireDefault(require("./config"));