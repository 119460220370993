// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/icons/pause.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../images/icons/mute.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../images/icons/full-screen.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../images/icons/play-button.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../images/icons/unmute.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "#compressed {\n  display: -moz-box;\n  -moz-box-pack: center;\n  -moz-box-align: center;\n  display: -webkit-box;\n  -webkit-box-pack: center;\n  -webkit-box-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  font-size: 12px; }\n  #compressed button {\n    cursor: pointer;\n    pointer-events: auto;\n    width: 6em;\n    margin: .5em;\n    border: 0;\n    background: none;\n    padding: 0; }\n    #compressed button:hover {\n      opacity: 0.7; }\n    #compressed button:focus {\n      outline: 0; }\n    #compressed button:focus {\n      outline: solid 2px #4d90fe; }\n    #compressed button:after {\n      content: \"\";\n      display: block;\n      padding-bottom: 100%;\n      border-color: transparent;\n      border-radius: 50%;\n      background-color: rgba(0, 0, 0, 0.6);\n      background-position: center center;\n      background-repeat: no-repeat; }\n  #compressed .play:after {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 20%; }\n  #compressed .mute:after {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    background-size: 45%; }\n  #compressed .fullscreen:after {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    background-position-y: 45%;\n    background-size: 45%; }\n  .paused #compressed .play:after {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n    background-size: 25%; }\n  .muted #compressed .mute:after {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + "); }\n", ""]);
// Exports
module.exports = exports;
