"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
var _lodash = _interopRequireDefault(require("lodash"));
var _fngPluginCaptions = _interopRequireDefault(require("../../plugins/fng-plugin-captions"));
var _fngUi = _interopRequireDefault(require("../../fng-ui"));
var _dom = require("../../util/dom");
/* eslint-disable no-unused-vars */
// TODO - registering as unused because of the way these
// methods are called from fng-plugin-captions

var hideCaptionsPlugin = function hideCaptionsPlugin(scope) {
  scope.captionsPlugin.hide();
  scope.captionSettingsVisible = false;
  if (scope.isMobile) {
    scope.showControlsBriefly();
  }
  if (scope.isTablet) {
    scope.showControls(false);
  }
  _lodash["default"].set(scope.settingsButton, 'children[0].src', _fngUi["default"].SETTINGS_BUTTON_OFF);
};
var showCaptionsPlugin = function showCaptionsPlugin(scope) {
  scope.captionsPlugin.show();
  scope.captionSettingsVisible = true;
  if (scope.isTablet) {
    scope.showControls(true);
  }
  _lodash["default"].set(scope.settingsButton, 'children[0].src', _fngUi["default"].SETTINGS_BUTTON_ON);
};
var toggleCaptions = function toggleCaptions(scope) {
  if (scope.isWebApp) {
    if (scope.player.captionsOn()) {
      scope.actions.showCaptions(0);
    } else {
      scope.actions.showCaptions(scope.options.captionTrackIndex || 1);
    }
    scope.focusElement(scope.ccButton);
  } else if (scope.player.captionsOn()) {
    _lodash["default"].set(scope.ccButton, 'children[0].src', scope.CC_BUTTON_OFF);
    scope.hideSettingsButton();
    scope.actions.showCaptions(0);
  } else {
    _lodash["default"].set(scope.ccButton, 'children[0].src', scope.CC_BUTTON_ON);
    scope.showSettingsButton();
    scope.actions.showCaptions(scope.options.captionTrackIndex || 1);
  }
  scope.ccButton.setAttribute('aria-pressed', scope.player.captionsOn());
};
var openCaptionsPlugin = function openCaptionsPlugin(scope) {
  scope.captionsPlugin = new _fngPluginCaptions["default"](scope.player, scope.actions);
  showCaptionsPlugin(scope);
};
var closeCaptionsPlugin = function closeCaptionsPlugin(scope) {
  hideCaptionsPlugin(scope);
  scope.captionsPlugin = null;
  scope.captionSettingsRendered = false;
};
var showCaptionsButton = function showCaptionsButton(scope) {
  if (scope.isVizio) return;
  scope.ccButton.disabled = false;
  (0, _dom.removeClass)(scope.ccButton, 'hide');
};
var handleCaptionsAvailable = function handleCaptionsAvailable(scope, e) {
  // the tracks array will always be populated with a default item of 'off'
  // even before captions are loaded
  if (e.tracks.length > 1) {
    showCaptionsButton(scope);
    if (scope.player.captionsOn()) {
      if (scope.isWebApp) {
        (0, _dom.addClass)(scope.ccButton, 'enabled');
      } else {
        _lodash["default"].set(scope.ccButton, 'children[0].src', scope.CC_BUTTON_ON);
        scope.showSettingsButton();
      }
    }
  }
};
module.exports = {
  showCaptionsButton: showCaptionsButton,
  handleCaptionsAvailable: handleCaptionsAvailable,
  openCaptionsPlugin: openCaptionsPlugin,
  toggleCaptions: toggleCaptions,
  showCaptionsPlugin: showCaptionsPlugin,
  hideCaptionsPlugin: hideCaptionsPlugin,
  closeCaptionsPlugin: closeCaptionsPlugin
};