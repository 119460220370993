"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.processAdInfo = exports.isAdPlaying = exports.getPreviousAdPod = exports.getNextUnwatchedAdPod = exports.getNextAdPod = exports.getAdPosition = exports.getAdPod = exports.getAdInPod = exports.checkVpaid = exports.adPositions = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/toConsumableArray"));
var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _from = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/from"));
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
var _now = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/date/now"));
var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));
var _reverse = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/reverse"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _lodash = _interopRequireDefault(require("lodash"));
var _logger = _interopRequireDefault(require("../helpers/logger"));
var _watch = require("./watch");
_lodash["default"].noConflict();
var log = new _logger["default"]('Util Ad');
var adPositions = {
  preroll: 'Pre-roll',
  midroll: 'Mid-roll',
  postroll: 'Post-roll'
};
exports.adPositions = adPositions;
var getAdPosition = function getAdPosition(ad) {
  return _lodash["default"].get(adPositions, ad.position, '');
};
exports.getAdPosition = getAdPosition;
var checkVpaid = function checkVpaid(ad) {
  return ad.apiFramework === 'vpaid' || ad.apiFramework === 'VPAID';
};
exports.checkVpaid = checkVpaid;
var processAdInfo = function processAdInfo(response, options) {
  var _context, _context2, _context3;
  // return if no ad data
  if (typeof response.ads === 'undefined' || typeof response.ads.breaks === 'undefined') {
    log.info("".concat(options.id, " | no ad info available for processing"));
    return null;
  }
  (0, _forEach["default"])(_context = response.ads.breaks).call(_context, function (adPod, adPodIndex) {
    adPod.index = adPodIndex;
  });

  // filter results for adPods that have ads
  options.adInfo = (0, _filter["default"])(_context2 = response.ads.breaks).call(_context2, function (adBreak) {
    return adBreak.ads.length > 0;
  });
  var adDuration = 0;
  var podIndex = 0;
  var isLive = options.mode === 'live';
  var callSign = (0, _watch.getWatchMetadata)(options.metadata, _watch.METADATA.ASSET_CALLSIGN);
  var midroll = 'midroll';
  var multiMidroll = _lodash["default"].countBy(options.adInfo, function (i) {
    return i.position === midroll;
  })["true"] > 1;
  var breakIndex = 0;
  // process ads to ensure the info we need is available
  (0, _forEach["default"])(_context3 = (0, _from["default"])(options.adInfo)).call(_context3, function (adPod) {
    var _context4;
    if (multiMidroll && adPod.position === midroll) {
      adPod.breakIndex = breakIndex;
      breakIndex += 1;
    }
    adPod.startTime = adPod.timeOffset;
    if (options.mode === 'live' && adPod.position === 'preroll') {
      adPod.startTime = adPod.timeOffset || 0;
    }
    adPod.duration = 0;
    adPod.adCount = 0;
    adPod.originalIndex = adPod.index;
    adPod.index = podIndex;
    var adCount = 0;
    (0, _forEach["default"])(_context4 = (0, _from["default"])(adPod.ads)).call(_context4, function (ad, adIndex) {
      var _context5;
      ad.isTrueX = false;
      ad.isVPAID = checkVpaid(ad);
      ad.startTime = adPod.startTime + adPod.duration;
      var fwParams = _lodash["default"].get(ad, 'fw_parameters', {});
      ad.skippable = (0, _includes["default"])(_context5 = _lodash["default"].get(ad, 'fw_parameters._fw_ad_unit_name', '').toLowerCase()).call(_context5, 'skippable');
      ad.url = ad.events && ad.events.clickthroughs && ad.events.clickthroughs.length > 0 ? ad.events.clickthroughs[0] : '';
      try {
        if (isLive) {
          if (ad.isTrueX || ad.isVPAID) {
            var error = new Error("[VPAID] Ad on live stream - ".concat(callSign));
            error.calledFrom = 'Util Ad(isLive - ad.isVPAID)';
            throw error;
          } else {
            adPod.adCount += 1;
            ad.index = adPod.adCount;
          }
        } else if (ad.isTrueX) {
          var _error = new Error("[TrueX] Ad on VOD stream - ".concat(callSign));
          _error.calledFrom = 'Util Ad(ad.isTrueX)';
          throw _error;
        } else if (ad.isVPAID) {
          var _context6;
          var placeholderOffset = (0, _find["default"])(_lodash["default"]).call(_lodash["default"], response.ads.placeholderOffsets, {
            breaksIndex: adPod.index,
            adsIndex: adIndex
          }) || {
            endTime: ad.duration,
            starTime: 0
          };
          ad.duration = placeholderOffset.endTime - placeholderOffset.starTime;
          adPod.adCount += 1;
          ad.index = adPod.adCount;
          if ((0, _indexOf["default"])(_context6 = ad.creative).call(_context6, 'https') === -1) {
            log.error('Ad Delivered Insecurely:', ad);
          }
        } else {
          adPod.adCount += 1;
          ad.index = adPod.adCount;
        }
      } catch (e) {
        log.error('Error Processing Ad', {
          ad: ad,
          e: e
        });
        e.data = ad;
      }

      // heartbeat requirement to add an index to all ads regardless of vpaid or 0 duration
      ad.originalIndex = ad.index;
      ad.index = adCount;
      ad.data = {
        title: fwParams._fw_ad_title,
        unitName: fwParams._fw_ad_unit_name,
        id: ad.ad_id,
        duration: ad.duration,
        position: fwParams._fw_ad_position_in_pod,
        creativeName: fwParams._fw_creative_name,
        creativeId: ad.creative_id,
        advertiser: fwParams._fw_advertiser_name,
        campaign: fwParams._fw_campaign_name,
        clickThrough: ad.url,
        adSystem: 'Freewheel',
        moat: fwParams.moat,
        huluIndustry: fwParams.hulu_industry,
        index: adCount,
        source: 'preplay'
      };
      adCount += 1;
      ad.endTime = ad.startTime + ad.duration;
      adPod.duration += ad.duration;
    });
    adPod.endTime = adPod.startTime + adPod.duration;
    adPod.priorAdTime = adDuration;
    podIndex += 1;
    adDuration += adPod.duration;
  });
  options.adDuration = adDuration;
  log.info("".concat(options.id, " | processed ads"), options.adInfo);
  return _lodash["default"].set(options, 'statistics.adLoadEnd', (0, _now["default"])());
};
exports.processAdInfo = processAdInfo;
var isAdPlaying = function isAdPlaying(options, time) {
  var adPlaying = false;
  if ((0, _isArray["default"])(options.adInfo)) {
    var _context7;
    (0, _forEach["default"])(_context7 = (0, _from["default"])(options.adInfo)).call(_context7, function (ad) {
      if (ad.startTime <= time && time <= ad.endTime) {
        adPlaying = true;
      }
    });
  }
  return adPlaying;
};

// finds specific ad or adPod from group
exports.isAdPlaying = isAdPlaying;
var findAdItem = function findAdItem() {
  var group = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var time = arguments.length > 1 ? arguments[1] : undefined;
  return (0, _find["default"])(group).call(group, function (item) {
    return item.startTime <= time && time <= item.endTime;
  });
};
var getAdPod = function getAdPod(options, time) {
  return findAdItem(options.adInfo || [], time);
};
exports.getAdPod = getAdPod;
var getAdInPod = function getAdInPod(adPod, time) {
  return findAdItem(adPod.ads || [], time);
};

// finds previous ad pod for specific time (by streamTime)
exports.getAdInPod = getAdInPod;
var getPreviousAdPod = function getPreviousAdPod(options, time) {
  var _context8, _context9;
  var reverse = (0, _reverse["default"])(_context8 = (0, _slice["default"])(_context9 = options.adInfo).call(_context9)).call(_context8);
  return (0, _find["default"])(reverse).call(reverse, function (adPod) {
    return time > adPod.endTime;
  });
};

// finds next ad pod for specific time
exports.getPreviousAdPod = getPreviousAdPod;
var getNextAdPod = function getNextAdPod(options, time) {
  var adInfo = options.adInfo || [];
  var activeAdPod = (0, _find["default"])(adInfo).call(adInfo, function (adPod) {
    return adPod.startTime <= time && adPod.endTime > time;
  });
  if (options.mode === 'live' && activeAdPod) {
    // thiw will make sure we get the ad break if ping api
    // sends us ad data AFTER the ad break was supposed to start
    return activeAdPod;
  }
  return (0, _find["default"])(adInfo).call(adInfo, function (adPod) {
    return adPod.startTime > time;
  });
};
exports.getNextAdPod = getNextAdPod;
var getNextUnwatchedAdPod = function getNextUnwatchedAdPod() {
  var _context10, _context11;
  var streamPosition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var adInfo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return (0, _find["default"])(_context10 = (0, _reverse["default"])(_context11 = (0, _toConsumableArray2["default"])(adInfo)).call(_context11)).call(_context10, function () {
    var adPod = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return streamPosition > adPod.startTime && !adPod.watched;
  });
};
exports.getNextUnwatchedAdPod = getNextUnwatchedAdPod;