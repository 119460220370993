"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.videoTypesMap = exports.playerTypes = exports.defaultValues = exports.contentAdTypeMap = exports.channels = exports.ADLOAD_TYPE = void 0;
var channels = {
  FOX: 'FOX',
  FX: 'FX East',
  FXW: 'FX West',
  FXX: 'FXX East',
  FXXW: 'FXX West',
  FXM: 'FXM',
  NGC: 'NatGeo East',
  NGCW: 'NatGeo West',
  NGEOWILD: 'NatGeo Wild',
  FS1: 'Fox Sports 1',
  FS2: 'Fox Sports 2',
  BTN: 'Big Ten Network',
  FOXDEP: 'Fox Deportes',
  FNC: 'Fox News Channel',
  FBN: 'Fox Business Network'
};
exports.channels = channels;
var videoTypesMap = {
  clip: 'short-form',
  fullepisode: 'long-form',
  movie: 'long-form',
  digitalexclusive: 'short-form',
  // NOTE: Will change
  live: 'long-form'
};
exports.videoTypesMap = videoTypesMap;
var ADLOAD_TYPE = {
  LINEAR: 1,
  DYNAMIC: 2
};
exports.ADLOAD_TYPE = ADLOAD_TYPE;
var contentAdTypeMap = {
  passthrough: ADLOAD_TYPE.LINEAR,
  mvpddai: ADLOAD_TYPE.DYNAMIC,
  dai: ADLOAD_TYPE.DYNAMIC,
  Live: ADLOAD_TYPE.LINEAR,
  CLEAN: ADLOAD_TYPE.DYNAMIC,
  c3: ADLOAD_TYPE.LINEAR,
  c7: ADLOAD_TYPE.LINEAR
};
exports.contentAdTypeMap = contentAdTypeMap;
var defaultValues = {
  seriesName: 'no show name',
  name: 'no content name',
  contentId: 'no content id',
  assetId: 'no asset id',
  seasonNumber: 'no season',
  episodeNumber: 'no episode',
  originalAirDate: 'no first airdate',
  firstDigitalDate: 'no first digital date',
  durationInSeconds: 3600,
  videoType: 'no format',
  genre: 'no genre',
  assetType: 'no type',
  contentAdType: 'no video type',
  contentRating: 'no content rating',
  source: 'no source',
  network: 'no network',
  playerid: 'no playerid',
  title: 'no title',
  closedcaptionoptions: 'no closed caption options',
  embeddedhost: 'no embedded host',
  requiresAuth: 'no subscription type',
  url: 'no url',
  ugcclip: 'none',
  mvpd: 'no mvpd',
  isResume: 'unknown',
  sportTag: 'no sport',
  autoplay: false,
  sponsor: 'no sponsor',
  feedname: 'no feed name',
  callSign: 'no stationid',
  isContinuous: false,
  screenLayout: 'no multiview',
  isRestart: 'unknown',
  dcg_profile_id: '',
  mediaId: 'no id',
  adPosition: 1,
  adLoad: 2,
  audioOnly: 'no audio',
  advertiser: 'no advertiser name',
  chapterName: 'no name',
  adName: 'no name',
  adUnitName: '',
  adCreativeName: '',
  adId: 'no id',
  streamFormat: 0,
  adType: 'no ad type',
  originator: 'no originator',
  crossDevicePlay: false,
  authorizingNetwork: 'no authorizing network',
  networkEntitlementList: 'no entitlements',
  'assetInfo.external_id': '',
  affwin: 'no affiliate window',
  programType: 'no type',
  advertisingID: 'no ad id',
  sessionID: 'no session id',
  vcid2: 'no vcid2',
  sitesection: 'no site section',
  tmsId: 'no tms id',
  airplay: 'false'
};
exports.defaultValues = defaultValues;
var playerTypes = {
  hero: 'hero inline player',
  standard: 'standard player'
};
exports.playerTypes = playerTypes;