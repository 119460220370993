"use strict";

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");
var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");
var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _forEachInstanceProperty2 = require("@babel/runtime-corejs3/core-js-stable/instance/for-each");
var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");
var _Object$defineProperties = require("@babel/runtime-corejs3/core-js-stable/object/define-properties");
var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.pick = exports.parseJSON = exports.omit = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));
var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));
function ownKeys(object, enumerableOnly) { var keys = _Object$keys(object); if (_Object$getOwnPropertySymbols) { var symbols = _Object$getOwnPropertySymbols(object); enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) { return _Object$getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var _context, _context2; var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? _forEachInstanceProperty2(_context = ownKeys(Object(source), !0)).call(_context, function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : _forEachInstanceProperty2(_context2 = ownKeys(Object(source))).call(_context2, function (key) { _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key)); }); } return target; }
var parseJSON = function parseJSON(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * Takes an object and returns only the key value pairs that are white listed
 * @param {object} obj
 * @param {array} whitelisted - The key names of the key value paris to return
 * @returns {object}
 */
exports.parseJSON = parseJSON;
var pick = function pick(obj, whitelisted) {
  var newObject = {};
  (0, _forEach["default"])(whitelisted).call(whitelisted, function (field) {
    if (obj[field]) {
      newObject = _objectSpread(_objectSpread({}, newObject), {}, (0, _defineProperty2["default"])({}, field, obj[field]));
    }
  });
  return newObject;
};
exports.pick = pick;
var omit = function omit(obj, blacklisted) {
  var newObject = _objectSpread({}, obj);
  (0, _forEach["default"])(blacklisted).call(blacklisted, function (field) {
    if (newObject[field]) {
      delete newObject[field];
    }
  });
  return newObject;
};
exports.omit = omit;