"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.setSubtitleCookie = exports.setMutedCookie = exports.setConvivaBackgroundedCookie = exports.setCaptionsCookie = exports.getSubtitleCookie = exports.getSiteSectionCookie = exports.getOptOutCookie = exports.getMutedCookie = exports.getDebugVideoOriginCookie = exports.getDebugVideoCdnCookie = exports.getConvivaBackgroundedCookie = exports.getCaptionsCookie = exports.getApiOverrideCookie = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _storage = require("../helpers/storage");
_lodash["default"].noConflict();
var getApiOverrideCookie = function getApiOverrideCookie() {
  var cookie = (0, _storage.getCookie)('API_BASEURL_OVERRIDE');
  return cookie === '' ? undefined : decodeURIComponent(cookie);
};
exports.getApiOverrideCookie = getApiOverrideCookie;
var getDebugVideoCdnCookie = function getDebugVideoCdnCookie() {
  var cookie = (0, _storage.getCookie)('DEBUG_PANEL_VIDEO_CDN');
  return cookie === '' ? undefined : decodeURIComponent(cookie);
};
exports.getDebugVideoCdnCookie = getDebugVideoCdnCookie;
var getDebugVideoOriginCookie = function getDebugVideoOriginCookie() {
  var cookie = (0, _storage.getCookie)('DEBUG_PANEL_VIDEO_ORIGIN');
  return cookie === '' ? undefined : decodeURIComponent(cookie);
};
exports.getDebugVideoOriginCookie = getDebugVideoOriginCookie;
var getSubtitleCookie = function getSubtitleCookie() {
  var cookie = (0, _storage.getCookie)('dfwSubtitles');
  return cookie === '' ? undefined : JSON.parse(cookie);
};
exports.getSubtitleCookie = getSubtitleCookie;
var getMutedCookie = function getMutedCookie() {
  var cookie = (0, _storage.getCookie)('dfwMuted');
  return cookie === '' ? undefined : JSON.parse(cookie);
};
exports.getMutedCookie = getMutedCookie;
var getConvivaBackgroundedCookie = function getConvivaBackgroundedCookie() {
  var cookie = (0, _storage.getCookie)('dfwBackgrounded');
  return cookie === '' ? undefined : JSON.parse(cookie);
};
exports.getConvivaBackgroundedCookie = getConvivaBackgroundedCookie;
var getCaptionsCookie = function getCaptionsCookie() {
  var cookie = (0, _storage.getCookie)('dfwCaptions');
  return cookie === '' ? undefined : JSON.parse(cookie);
};
exports.getCaptionsCookie = getCaptionsCookie;
var getOptOutCookie = function getOptOutCookie() {
  var cookie = (0, _storage.getCookie)('us_privacy');
  return cookie === '' ? undefined : cookie;
};
exports.getOptOutCookie = getOptOutCookie;
var getSiteSectionCookie = function getSiteSectionCookie() {
  var cookie = (0, _storage.getCookie)('DEBUG_PANEL_AD_SITE_SECTION');
  return cookie === '' || cookie === 'null' ? undefined : decodeURIComponent(cookie);
};
exports.getSiteSectionCookie = getSiteSectionCookie;
var setCaptionsCookie = function setCaptionsCookie(value) {
  (0, _storage.setCookie)('dfwCaptions', value);
};
exports.setCaptionsCookie = setCaptionsCookie;
var setSubtitleCookie = function setSubtitleCookie(value) {
  (0, _storage.setCookie)('dfwSubtitles', value);
};
exports.setSubtitleCookie = setSubtitleCookie;
var setMutedCookie = function setMutedCookie(value) {
  (0, _storage.setCookie)('dfwMuted', value);
};
exports.setMutedCookie = setMutedCookie;
var setConvivaBackgroundedCookie = function setConvivaBackgroundedCookie(value) {
  (0, _storage.setCookie)('dfwBackgrounded', value);
};
exports.setConvivaBackgroundedCookie = setConvivaBackgroundedCookie;