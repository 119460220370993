"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));
var _startsWith = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/starts-with"));
require("core-js/modules/es.date.to-string.js");
var cookie = {
  set: function set(name, value, secondsToExpire) {
    var _context, _context2, _context3;
    var path = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '/';
    var domain = arguments.length > 4 ? arguments[4] : undefined;
    if (typeof value === 'undefined') return;
    var date = new Date();
    if (secondsToExpire) {
      date.setTime(date.getTime() + secondsToExpire * 1000);
    }
    var cookie = (0, _concat["default"])(_context = (0, _concat["default"])(_context2 = (0, _concat["default"])(_context3 = "".concat(name, "=")).call(_context3, value || '', "; expires=")).call(_context2, date.toUTCString(), "; path=")).call(_context, path);
    if (domain) {
      var _context4;
      cookie = (0, _concat["default"])(_context4 = "".concat(cookie, "; domain=")).call(_context4, domain);
    }
    document.cookie = cookie;
  },
  get: function get(name) {
    var _document$cookie, _context5;
    var cookie = (_document$cookie = document.cookie) === null || _document$cookie === void 0 ? void 0 : (0, _find["default"])(_context5 = _document$cookie.split('; ')).call(_context5, function (cookie) {
      return (0, _startsWith["default"])(cookie).call(cookie, name);
    });
    if (!cookie) return;
    return cookie.split('=')[1];
  },
  "delete": function _delete(name) {
    var _context6, _context7;
    var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
    var date = new Date(0);
    document.cookie = (0, _concat["default"])(_context6 = (0, _concat["default"])(_context7 = "".concat(name, "=; expires=")).call(_context7, date.toUTCString(), "; path=")).call(_context6, path);
  }
};
var _default = cookie;
exports["default"] = _default;