"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.getTimestamp = exports.getReadableTime = exports.getLiveOffset = exports.getFormattedTime = exports.getFormattedDatePST = exports.getDate = exports.formatDuration = void 0;
var _isNan = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/number/is-nan"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));
var _now = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/date/now"));
require("core-js/modules/es.date.to-string.js");
var _lodash = _interopRequireDefault(require("lodash"));
_lodash["default"].noConflict();

/**
 * Wrapper for the native JS date.  Used for tests.
 * @returns {Date}
 */
var getDate = function getDate() {
  return new Date();
};
exports.getDate = getDate;
var getFormattedDatePST = function getFormattedDatePST() {
  var date = new Date();
  var options = {
    timeZone: 'America/Los_Angeles',
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZoneName: 'short'
  };
  return date.toLocaleString('en-US', options);
};
exports.getFormattedDatePST = getFormattedDatePST;
var getReadableTime = function getReadableTime(sec) {
  var time = '0:00';
  if ((0, _isNan["default"])(sec) || !sec) return time;
  var seconds = Math.floor(sec);
  var hours = 0;
  if (seconds >= 60) {
    var minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds % 60);
    if (seconds < 10) {
      seconds = "0".concat(seconds);
    }
    if (minutes >= 60) {
      var _context, _context2;
      hours = Math.floor(minutes / 60);
      if (hours < 10) hours = "0".concat(hours);
      minutes = Math.floor(minutes % 60);
      if (minutes < 10) minutes = "0".concat(minutes);
      time = (0, _concat["default"])(_context = (0, _concat["default"])(_context2 = "".concat(hours, ":")).call(_context2, minutes, ":")).call(_context, seconds);
    } else {
      var _context3;
      time = (0, _concat["default"])(_context3 = "".concat(minutes, ":")).call(_context3, seconds);
    }
  } else {
    if (seconds < 10) {
      seconds = "0".concat(seconds);
    }
    time = "0:".concat(seconds);
  }
  return time;
};
exports.getReadableTime = getReadableTime;
var getFormattedTime = function getFormattedTime() {
  var _context4, _context5;
  var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  minutes = minutes < 10 ? "0".concat(minutes) : minutes;
  return (0, _concat["default"])(_context4 = (0, _concat["default"])(_context5 = "".concat(hours, ":")).call(_context5, minutes)).call(_context4, ampm);
};
exports.getFormattedTime = getFormattedTime;
var formatDuration = function formatDuration() {
  var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var milliseconds = (0, _parseInt2["default"])(duration % 1000, 10);
  var seconds = (0, _parseInt2["default"])(duration / 1000 % 60, 10);
  var minutes = (0, _parseInt2["default"])(duration / (1000 * 60) % 60, 10);
  var hours = (0, _parseInt2["default"])(duration / (1000 * 60 * 60) % 24, 10);
  var time = '';
  if (hours) time += "".concat(hours, " hours ");
  if (minutes) time += "".concat(minutes, " minutes ");
  if (seconds) time += "".concat(seconds, " seconds ");
  if (milliseconds) time += "".concat(milliseconds, " milliseconds ");
  return time;
};
exports.formatDuration = formatDuration;
var getTimestamp = function getTimestamp() {
  var ds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var timeDate = ds ? new Date("".concat(ds, "+00:00")) : new Date();
  return (0, _parseInt2["default"])(timeDate.getTime() / 1000, 10);
};
exports.getTimestamp = getTimestamp;
var getLiveOffset = function getLiveOffset(wallclock) {
  var isDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var now = (0, _now["default"])();
  if (!isDate) return (0, _parseInt2["default"])((now - wallclock) / 1000, 10);
  var ds = "".concat(wallclock, "+00:00");
  var timeDate = new Date(ds);
  return (0, _parseInt2["default"])((now - timeDate.getTime()) / 1000, 10);
};
exports.getLiveOffset = getLiveOffset;