"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.updateClass = exports.removeEventListener = exports.removeClasses = exports.removeClass = exports.isElementInViewport = exports.getPrevSibling = exports.getNextSibling = exports.fixDropDowns = exports.elementRemovedObserver = exports.addEventListener = exports.addClass = void 0;
var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));
var _from = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/from"));
var _isArray = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/array/is-array"));
var _lodash = _interopRequireDefault(require("lodash"));
_lodash["default"].noConflict();
var elementRemovedObserver = function elementRemovedObserver(_ref) {
  var elementId = _ref.elementId,
    _ref$targetNode = _ref.targetNode,
    targetNode = _ref$targetNode === void 0 ? document : _ref$targetNode,
    _ref$action = _ref.action,
    action = _ref$action === void 0 ? _lodash["default"].noop : _ref$action;
  // can observer attributes, children and subtree
  var config = {
    childList: true,
    subtree: true
  };
  var checkMutations = function checkMutations() {
    var mutationsList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    (0, _forEach["default"])(mutationsList).call(mutationsList, function (mutation) {
      var _mutation$removedNode = mutation.removedNodes,
        removedNodes = _mutation$removedNode === void 0 ? [] : _mutation$removedNode;
      var removedNode = removedNodes[0];
      if (mutation.type === 'childList' && removedNode) {
        // if player was removed
        var element = targetNode.querySelector("#".concat(elementId));
        if (!element && action) action();
      }
    });
  };
  var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
  var observer = null;
  if (MutationObserver && MutationObserver.constructor) {
    observer = new MutationObserver(checkMutations);
    observer.observe(targetNode, config);
  }
  return observer;
};

// adds class to element (if it isn't already present)
exports.elementRemovedObserver = elementRemovedObserver;
var addClass = function addClass(element, className) {
  if (!element) return;
  if (_lodash["default"].get(element, 'classList')) {
    if (!element.classList.contains(className)) {
      element.classList.add(className);
    }
  } else {
    element.className = className;
  }
};

// removes class from element (if present)
exports.addClass = addClass;
var removeClass = function removeClass(element, className) {
  if (!element) return;
  if (_lodash["default"].get(element, 'classList')) {
    if (element.classList.contains(className)) {
      element.classList.remove(className);
    }
  }
};
exports.removeClass = removeClass;
var updateClass = function updateClass(element, className, enable) {
  if (element) {
    if (element.classList) {
      if (enable && !element.classList.contains(className)) {
        element.classList.add(className);
      } else if (!enable && element.classList.contains(className)) {
        element.classList.remove(className);
      }
    } else if (enable) {
      element.className = className;
    }
  }
};
exports.updateClass = updateClass;
var removeClasses = function removeClasses(element, classArray) {
  if (element.classList) {
    (0, _forEach["default"])(classArray).call(classArray, function (className) {
      if (element.classList.contains(className)) {
        element.classList.remove(className);
      }
    });
  }
};
exports.removeClasses = removeClasses;
var fixDropDowns = function fixDropDowns(collection) {
  var _context;
  (0, _forEach["default"])(_context = (0, _from["default"])(collection)).call(_context, function (element) {
    element.addEventListener('mouseup', function (e) {
      return e.stopPropagation();
    });
  });
};
exports.fixDropDowns = fixDropDowns;
var addEventListener = function addEventListener(elem, listener) {
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'click';
  var eventMap = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  if (!elem) return;
  if (_lodash["default"].isFunction(elem.addEventListener)) {
    if ((0, _isArray["default"])(type)) {
      (0, _forEach["default"])(type).call(type, function (eventType) {
        elem.addEventListener(eventType, listener);
        eventMap.push({
          elem: elem,
          listener: listener,
          eventType: eventType
        });
      });
    } else {
      elem.addEventListener(type, listener);
      eventMap.push({
        elem: elem,
        listener: listener,
        type: type
      });
    }
  }
};
exports.addEventListener = addEventListener;
var removeEventListener = function removeEventListener(elem, listener) {
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'click';
  if (_lodash["default"].isFunction(elem.removeEventListener)) {
    if ((0, _isArray["default"])(type)) {
      (0, _forEach["default"])(type).call(type, function (eventType) {
        elem.removeEventListener(eventType, listener);
      });
    } else {
      elem.removeEventListener(type, listener);
    }
  }
};
exports.removeEventListener = removeEventListener;
var getNextSibling = function getNextSibling(elem, selector) {
  var sibling = elem.nextElementSibling;
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }
  return null;
};
exports.getNextSibling = getNextSibling;
var getPrevSibling = function getPrevSibling(elem, selector) {
  var sibling = elem.previousElementSibling;
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.previousElementSibling;
  }
  return null;
};
exports.getPrevSibling = getPrevSibling;
var isElementInViewport = function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
};
exports.isElementInViewport = isElementInViewport;