"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _uaParserJs = _interopRequireDefault(require("ua-parser-js"));
var getDeviceInfo = function getDeviceInfo() {
  var _window;
  if (typeof ((_window = window) === null || _window === void 0 ? void 0 : _window.navigator) === 'undefined') return null;
  return (0, _uaParserJs["default"])();
};
var _default = getDeviceInfo;
exports["default"] = _default;