"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.SDK_PLATFORM = exports.MISSING_REQUIRED_PARAMS_MESSAGE = exports.EVENT_TYPE_USAGE = exports.EVENT_TYPE_ERROR = exports.EVENT_SUBTYPE_INIT = exports.EVENT_EXCEPTION_TYPE_UNHANDLED = exports.EVENT_EXCEPTION_SUB_TYPE_UNHANDLED = exports.EVENT_EXCEPTION_SUB_TYPE_HANDLED = exports.DATA_DOG_BASE_PATH = exports.CLIENT_TOKENS = void 0;
var DATA_DOG_BASE_PATH = 'https://logs.browser-intake-datadoghq.com/api/v2/';
exports.DATA_DOG_BASE_PATH = DATA_DOG_BASE_PATH;
var CLIENT_TOKENS = {
  dev: 'pub1916f5085d50523f1bcc77c791df30b0',
  prod: 'pub777582d7073fbc6a08d7b856ca3c3b05'
};
exports.CLIENT_TOKENS = CLIENT_TOKENS;
var EVENT_TYPE_USAGE = 'SdkUsage';
exports.EVENT_TYPE_USAGE = EVENT_TYPE_USAGE;
var EVENT_TYPE_ERROR = 'SdkError';
exports.EVENT_TYPE_ERROR = EVENT_TYPE_ERROR;
var EVENT_SUBTYPE_INIT = 'SdkInit';
exports.EVENT_SUBTYPE_INIT = EVENT_SUBTYPE_INIT;
var EVENT_EXCEPTION_TYPE_UNHANDLED = 'UnhandledException';
exports.EVENT_EXCEPTION_TYPE_UNHANDLED = EVENT_EXCEPTION_TYPE_UNHANDLED;
var EVENT_EXCEPTION_SUB_TYPE_HANDLED = 'handled';
exports.EVENT_EXCEPTION_SUB_TYPE_HANDLED = EVENT_EXCEPTION_SUB_TYPE_HANDLED;
var EVENT_EXCEPTION_SUB_TYPE_UNHANDLED = 'unhandled';
exports.EVENT_EXCEPTION_SUB_TYPE_UNHANDLED = EVENT_EXCEPTION_SUB_TYPE_UNHANDLED;
var MISSING_REQUIRED_PARAMS_MESSAGE = 'DataDog analytics post not fired due to missing required parameters';
exports.MISSING_REQUIRED_PARAMS_MESSAGE = MISSING_REQUIRED_PARAMS_MESSAGE;
var SDK_PLATFORM = 'JS';
exports.SDK_PLATFORM = SDK_PLATFORM;