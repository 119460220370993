"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _now = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/date/now"));
require("core-js/modules/es.date.to-string.js");
require("core-js/modules/es.function.name.js");
var writeVSTLog = function writeVSTLog(options) {
  var firstFrameTime = new Date().toUTCString();
  /* eslint-disable */
  console.log("%c FIRST VIDEO FRAME LOADED : ".concat(firstFrameTime), 'background: #222; color: #bada55; font-weight: bold');
  /* eslint-enable */
  options.firstFrameTime = (0, _now["default"])();
  var VST = Math.abs(options.firstFrameTime - options.sessionRequestTime);
  var nonWatch = Math.abs(VST - options.watchRequestTime);
  function Benchmark(name, time) {
    this.name = name;
    this.time = time;
  }
  var totalVST = new Benchmark('Total Calculated VST', VST);
  var watchRequestTime = new Benchmark('Watch Request Time', options.watchRequestTime);
  var nonWatchVST = new Benchmark('Non Watch VST', nonWatch);
  /* eslint-disable */
  console.table([totalVST, watchRequestTime, nonWatchVST]);
  /* eslint-enable */
};
var _default = writeVSTLog;
exports["default"] = _default;