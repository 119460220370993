"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var errorMessages = {
  GEO_LOCATION_ERROR: 'This content is not available in your location.'
};
var _default = errorMessages;
exports["default"] = _default;