"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
_Object$defineProperty(exports, "__esModule", {
  value: true
});
exports.podStart = exports.podEnd = exports.defaultValues = exports.adPositions = void 0;
// This file contains helpers shared between Conviva plugin implementations
var podStart = 'Conviva.PodStart';
exports.podStart = podStart;
var podEnd = 'Conviva.PodEnd';
exports.podEnd = podEnd;
var adPositions = {
  preroll: 'Pre-roll',
  midroll: 'Mid-roll',
  postroll: 'Post-roll'
};
exports.adPositions = adPositions;
var defaultValues = {
  playerName: '',
  appName: '',
  brand: '',
  channel: '',
  connectionType: '',
  contentId: '',
  contentType: '',
  freewheelSiteSection: '',
  localStation: '',
  mvpd: '',
  playerVersion: '',
  show: '',
  source: '',
  uID: '',
  userStatus: '',
  trackingData: {},
  network: 'fox',
  requiresAuth: false
};
exports.defaultValues = defaultValues;