"use strict";

var _typeof3 = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
require("core-js/modules/es.date.to-string.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/typeof"));
var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));
var util = _interopRequireWildcard(require("util"));
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof3(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = _Object$defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { _Object$defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var settings = {
  attributes_key: false,
  header: false
};
module.exports = function xml(json, opts) {
  if (opts) {
    var _context;
    (0, _forEach["default"])(_context = (0, _keys["default"])(settings)).call(_context, function (k) {
      if (opts[k] === undefined) {
        opts[k] = settings[k];
      }
    });
  } else {
    opts = settings;
  }
  var result = opts.header ? '<?xml version="1.0" encoding="UTF-8"?>' : '';
  opts.header = false;
  if (!!json.length && typeof json !== 'string') {
    // Array
    (0, _forEach["default"])(json).call(json, function (node) {
      result += xml(node, opts);
    });
  } else if ((0, _typeof2["default"])(json) === 'object') {
    var _context2;
    (0, _forEach["default"])(_context2 = (0, _keys["default"])(json)).call(_context2, function (key) {
      if (key !== opts.attributes_key) {
        var node = json[key];
        var attributes = '';
        if (node === undefined || node === null) {
          node = '';
        }
        if (opts.attributes_key && json[opts.attributes_key]) {
          var _context3;
          (0, _forEach["default"])(_context3 = (0, _keys["default"])(json[opts.attributes_key])).call(_context3, function (k) {
            attributes += util.format(' %s="%s"', k, json[opts.attributes_key][k]);
          });
        }
        var inner = xml(node, opts);
        if (key === 'ImpressionString') {
          result += json[key];
        } else if (inner) {
          result += util.format('<%s%s>%s</%s>', key, attributes, xml(node, opts), key);
        } else {
          result += util.format('<%s%s></%s>', key, attributes, key);
        }
      }
    });
  } else if (typeof json === 'string' && (0, _indexOf["default"])(json).call(json, '<![CDATA[') === 0) {
    return json.toString();
  } else {
    return json.toString().replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  }
  return result;
};